/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Patua+One&display=swap');

/* Page title */
/* #title { */
  /* text-align: center;
  font-size: 120%;
  margin-top: -20px; */
/* } */

#title {
	text-transform: uppercase;
	letter-spacing: .3em;
	display: inline-block;
  text-align: center;
	border-width: 4px 0;
	/* padding: 1.5em 0em; */
	/* position: absolute; */
	top: 18%;
	left: 50%;
	width: 100%;
	margin: 0 0 0 0;
}
#lowerTitle{
  	font: 120% "Patua One", sans-serif;
  	letter-spacing: 0;
  	padding: .20em 0 .325em;
	  display: block;
	  margin: 0 auto;
  	text-shadow: 0 0 80px rgba(255,255,255,.5); 
}
/* Clip Background Image */
/* 
	  background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	  -webkit-background-clip: text;
	  background-clip: text; */

/* Animate Background Image */
/* 
	  -webkit-text-fill-color: transparent;
	  -webkit-animation: aitf 80s linear infinite; */

/* Activate hardware acceleration for smoother animations */
/* 
	  -webkit-transform: translate3d(0,0,0);
	  -webkit-backface-visibility: hidden;

  } */

/* Animate Background Image */

/* @-webkit-keyframes aitf {
	0% { background-position: 0% 50%; }
	100% { background-position: 100% 50%; }
} */

/* Main container */
#mainThing {
  margin: auto;
  padding: 2%;
  font-size: 0.6em;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  border: 1px solid grey;
  border-radius: 25px;
}

/* Moving background */
.wrapper {
  animation: scroll 70s linear infinite;
  background-image: url('../src/background/background1.png');
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

@keyframes scroll {
  100% {
    background-position: 0px -3000px;
  }
}

@media (prefers-reduced-motion) {
  .wrapper {
    animation: scroll 200s linear infinite;
  }
}

@media (min-width: 670px) {
  .title {
    font-size: 5rem;
  }
}

/* Main content */
#main {
  font-family: 'Patua One';
  font-size: 70px;
  text-align: center;
  background: white;
  border-radius: 25px;
  padding: 2%;
  border: 3px solid black;
  min-width: 85%;
  max-width: 85%;
  /* margin: 20px 0; */
  max-height: 85%;
}

#font {
  font-size: 30px;
}

/* Buttons */
.btn, .modalbtn {
  outline: none;
  background: #e92121;
  color: #fff;
  border-radius: 40px;
  text-align: center;
  position: relative;
  margin-top: 15px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-family: 'Patua One';
}

.btn {
  width: 150px;
  height: 50px;
  font-size: 22px;
}

.modalbtn {
  width: 70px;
  height: 35px;
  font-size: 16px;
}

.btn:hover, .modalbtn:hover {
  outline: 4px solid #c86868; 
  background: #c35656;
  outline-offset: 3px;
  color: #fff;
}

.modalTitle{
  color: black;
  font-size: 30px !important;
}

/* Center alignment */
.centerMe {
  text-align: center;
  justify-content: center
}

/* Modal GIF */
.modalGif {
  max-width: 100%;
  height: 300px;
  width: auto;
  border-radius: 3%;
}

/* Warning text */
#enterName {
  font-size: 1.5rem;
  color: red;
}

/* Vertical spacing */
#space {
  height: 20px;
}

/* Number display */
.numbers {
  font-size: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Gender buttons */
.genderBtn {
  min-width: 100%;
  height: 300px;
  max-height: 100%;
  margin: 1%;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.genderBtn:hover {
  cursor: pointer;
  border: 3px black solid;
  -webkit-box-shadow: inset 3px 3px 20px #333;
  -moz-box-shadow: inset 3px 3px 20px #333;
  box-shadow: inset 3px 3px 20px #333;
}

/* Text style */
#textBig {
  margin-top: 60px;
  text-align: center;
  font-size: 35px;
}

/* Section with background image */
.module {
  background: 
    linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.351)
    ),
    url(./images/20220526_131656.jpg);
  background-size: cover;
  height: 45vh; /* 30% of the viewport height */
  width: 100%; /* 50% of the parent element's width */
  margin-top: 70px;
  position: relative;
  float: left;
  border-radius: 20px;
}
/* Pie chart text size and style */
#pie {
  font-size: 30px;
}

h2 {
  color: white;
  margin: 0;
  padding: 20px;
}

/* Pie chart legend icons */
#boyVoteSquare {
  background-color: rgb(255, 252, 155);
}

#girlVoteSquare {
  background-color: rgb(205, 155, 255);
}

.legend-text{
  margin-left: 10px;
}

#girlTotal {
  /* margin-left: auto; */
  /* margin-left: 2px; */
  margin-left: 60px;
}

#boyTotal {
  /* margin-right: auto; */
}

.vote-square {
  width: 35px;
  height: 35px;
  border: black 1px solid;
  border-radius: 30px;
  /* margin-right: 10px; */
}

/* Gender button hover styles */
#girlBtn:hover {
  background: rgb(205, 155, 255);
}

#boyBtn:hover {
  background: rgb(255, 252, 155);
}

/* Chart legend styles */
#legend{
  align-items: center;
  display: flex;
}

.chart-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 25px; 
}

.legend-item {
  display: flex;
  align-items: center;
}

#titleChart {
  font-size: 30px;
}

/* Chart styles */
.chart-container {
  padding: 0; /* Remove vertical padding */
  margin-top: -80px; /* Adjust as needed */
  margin-bottom: -80px; /* Adjust as needed */
}